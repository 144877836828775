import React, { FC } from "react"
import { Stack, Text, Cluster } from "../common"
import { NextIcon } from "../common/icons/NextIcon"
export const PointsList: FC<{ data: Array<{ title: string }> }> = props => {
  return (
    <Stack space="var(--s1)">
      {props.data.map(item => (
        <Cluster space="var(--s1)">
          <NextIcon />
          <Text
            variant="h3"
            style={{ fontFamily: "var(--heading-font-regular)" }}
          >
            {item.title}
          </Text>
        </Cluster>
      ))}
      <Text
        variant="h4"
        style={{ fontWeight: "normal", fontFamily: "var(--body-font-family)" }}
      >
        *this list is indicative and not exhaustive
      </Text>
    </Stack>
  )
}
