import { css } from "@emotion/core"
import React, { FC } from "react"
import { Stack, Box, Switcher, Text } from "../common"
import { Grid } from "../common/components/Grid"
import { NextIcon } from "../common/icons/NextIcon"
export const PointsGrid: FC<{
  data: Array<{ title: string; content: string[] }>
}> = props => {
  return (
    <Box style={{ paddingTop: "var(--s3)", paddingBottom: "var(--s3)" }}>
      <Grid gap="var(--s4)" min="500px">
        {props.data.map(item => (
          <Box>
            <Switcher max={2} threshold="0px" space="var(--s0)">
              <NextIcon />
              <Stack space="var(--s0)" style={{ flexGrow: 15 }}>
                <Text variant="h3">{item.title}</Text>
                <ul
                  style={{ listStyleType: "none" }}
                  css={css`
                    p {
                      margin-bottom: var(--s-2);
                    }
                  `}
                >
                  {item.content.map(str => (
                    <li>
                      <Text
                        variant="h4"
                        color="light"
                        style={{ fontFamily: "var(--body-font-family)" }}
                      >
                        {str}
                      </Text>
                    </li>
                  ))}
                </ul>
              </Stack>
            </Switcher>
          </Box>
        ))}
      </Grid>
    </Box>
  )
}
