import React, { FC } from "react"
import { Text } from "../common"
import { Tabs as AntTabs } from "antd"
import { css } from "@emotion/core"

type TabsProps = {
  title: string
  key: string
  element: React.ReactNode,
  centered ? : boolean
}
const Tabs: FC<{ tabs: TabsProps[] }> = props => {
  return (
    <AntTabs centered ={props.centered}
      css={css`
        .ant-tabs-ink-bar {
          background-color: var(--secondary-color);
          height: 4px !important;
          width:'auto'
          
        }
    
        .ant-tabs-nav-list {
           @media screen and (min-width: 1200px) {
                   width:100%
                   
                 }
                  @media screen and (max-width: 1200px) {
                   width:'auto'
                   
                 }
          display: flex;
          justify-content: space-between;
        } 

         .ant-tabs-tab {
          width: 100%;
        }

        .ant-tabs-tab-btn {
          width: 100%;
        } */
    
        
      `}
      // size="small"
    >
      {props.tabs.map(tab => (
        <AntTabs.TabPane
          key={tab.key}
          tab={
            <Text
              style={{ margin: '0', textAlign: "center",flexWrap:"wrap",flexShrink: 1, }}
              variant="h3"
            >
              {tab.title}
            </Text>
          }
        >
          {tab.element}
        </AntTabs.TabPane>
      ))}
    </AntTabs>
  )
}

export { Tabs }
