import React, { FC } from "react"
import { Switcher, Center, Stack, Box, Text } from "../common"
import { Clock } from "../common/icons/ClockIcon"
import { Money } from "../common/icons/MoneyIcon"
import { Repayment } from "../common/icons/RepaymentIcon"
import { Bell } from "../common/icons/BellIcon"
import { css } from "@emotion/core"

type FEATURE_PROP = {
  title: string
  content: string
}

const ProductFeatures: FC<{ features: FEATURE_PROP[] }> = props => {
  return (
    <Box>
      <Center gutters="var(--s2)" maxWidth="var(--max-width)">
        <Stack space="var(--s3)">
          <Text
            style={{ opacity: 0.2, textTransform: "uppercase" }}
            variant="h2"
            color="light"
            css={css`
              @media screen and (max-width: 600px) {
                text-align: center;
              }
            `}
          >
            Features & Benefits
          </Text>
          <Switcher max={4} threshold="var(--min-width)" space="var(--s3)">
            <Feature icon={<Clock />} feature={props.features[0]} />
            <Feature icon={<Money />} feature={props.features[1]} />
            <Feature icon={<Repayment />} feature={props.features[2]} />
            <Feature icon={<Bell />} feature={props.features[3]} />
          </Switcher>
        </Stack>
      </Center>
    </Box>
  )
}

export { ProductFeatures }

const Feature: FC<{ icon: React.ReactNode; feature: FEATURE_PROP }> = props => {
  return (
    <Box>
      <Stack justify="center" align="center" space="var(--s1)">
        {props.icon}
        <Stack space="var(--s-3)">
          <Text
            style={{ textAlign: "center", lineHeight: "26px" }}
            variant="h3"
          >
            {props.feature.title}
          </Text>
          <Text style={{ textAlign: "center" }} variant="p">
            {props.feature.content}
          </Text>
        </Stack>
      </Stack>
    </Box>
  )
}
