import React from "react"

function Money() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      fill="none"
      viewBox="0 0 100 100"
    >
      <path
        fill="#001F79"
        d="M99.865 53.435v-.002L81.74 7.924a1.885 1.885 0 00-2.45-1.054L5.758 36.15c-.3.13-.56.336-.754.6A1.864 1.864 0 004 38.383V87.38c0 1.04.844 1.885 1.885 1.885h79.149c1.04 0 1.884-.844 1.884-1.885v-26.76l11.895-4.738a1.884 1.884 0 001.052-2.448zM83.15 85.496H7.77V40.268h75.38v45.228zM35.443 36.51l31.554-12.574a14.994 14.994 0 0010.804 4.659l3.147 7.915H35.443zm51.475 20.064v-18.19c0-1.04-.844-1.884-1.884-1.884h-.03L81.01 26.47a1.566 1.566 0 00-.154-.237 1.862 1.862 0 00-1.97-1.451 11.27 11.27 0 01-9.88-4.256 1.868 1.868 0 00-2.418-.422c-.09.01-.178.027-.265.05L27.799 35.492c-.466.183-.839.545-1.034 1.006h-11.7l63.87-25.431 16.733 42.015-8.75 3.49z"
      ></path>
      <path
        fill="#F66B02"
        d="M12.884 72.774a11.268 11.268 0 017.607 7.598 1.884 1.884 0 001.806 1.346c.098-.006.194-.02.29-.044.085.024.171.042.258.053h45.228a1.62 1.62 0 00.23-.047 1.755 1.755 0 002.12-1.29l.002-.01a11.267 11.267 0 017.608-7.608 1.86 1.86 0 001.293-2.073c.025-.091.043-.185.054-.279V55.344a1.698 1.698 0 00-.056-.279 1.858 1.858 0 00-1.293-2.073 11.256 11.256 0 01-7.604-7.61 1.862 1.862 0 00-2.088-1.292 1.783 1.783 0 00-.266-.053H22.845a1.79 1.79 0 00-.28.057 1.862 1.862 0 00-2.074 1.292 11.268 11.268 0 01-7.607 7.606 1.86 1.86 0 00-1.293 2.073 1.904 1.904 0 00-.053.28V70.42c.01.089.029.176.053.262a1.866 1.866 0 001.293 2.092zm2.423-16.665a14.954 14.954 0 008.303-8.303h43.696a14.958 14.958 0 008.305 8.303v13.546a14.971 14.971 0 00-8.303 8.303H23.61a14.971 14.971 0 00-8.303-8.303V56.11z"
      ></path>
      <path
        fill="#F66B02"
        d="M45.459 74.19c6.245 0 11.307-5.062 11.307-11.307s-5.062-11.307-11.307-11.307-11.307 5.062-11.307 11.307c.006 6.242 5.065 11.301 11.307 11.307zm0-18.845a7.538 7.538 0 110 15.076 7.538 7.538 0 010-15.076zM24.73 65.708a2.827 2.827 0 100-5.653 2.827 2.827 0 000 5.653zm0-3.769a.942.942 0 110 1.885.942.942 0 010-1.885zM66.189 65.708a2.827 2.827 0 100-5.653 2.827 2.827 0 000 5.653zm0-3.769a.942.942 0 110 1.885.942.942 0 010-1.885z"
      ></path>
    </svg>
  )
}

export { Money }
