import React from "react"

function Bell() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      fill="none"
      viewBox="0 0 100 100"
    >
      <path
        fill="#F66B02"
        d="M49.009 47A9 9 0 0058 37.991 9 9 0 0048.99 29 9 9 0 0040 38.009 9.02 9.02 0 0049.009 47zm0-14.515a5.524 5.524 0 110 11.047 5.524 5.524 0 010-11.047zM36.763 67h24.474c.973-.002 1.76-.826 1.763-1.844 0-4.167-1.445-8.002-4.09-10.823C56.294 51.56 52.725 50 49 50c-3.724 0-7.293 1.56-9.91 4.333C36.447 57.154 35 60.989 35 65.156c.003 1.018.79 1.842 1.763 1.844zm4.85-10.086c1.95-2.064 4.611-3.226 7.387-3.226s5.437 1.162 7.388 3.226a11.549 11.549 0 012.963 6.398H38.65a11.49 11.49 0 012.962-6.398z"
      ></path>
      <path
        fill="#001F79"
        d="M72.111 13.975h-16V9.757A1.773 1.773 0 0054.333 8H43.667a1.773 1.773 0 00-1.778 1.757v4.218h-16c-4.908.002-8.887 3.935-8.889 8.787v60.451c.002 4.852 3.98 8.785 8.889 8.787H72.11c4.908-.002 8.887-3.935 8.889-8.787V22.762c-.002-4.852-3.98-8.785-8.889-8.787zm-26.667-2.46h7.112v7.029h-7.112v-7.03zm32 71.698c-.008 2.908-2.391 5.264-5.333 5.272H25.89c-2.942-.008-5.325-2.364-5.333-5.272V22.762c.008-2.908 2.391-5.264 5.333-5.273h16v2.812c.003.97.797 1.755 1.778 1.758h10.666a1.773 1.773 0 001.778-1.758V17.49h16c2.942.008 5.325 2.364 5.333 5.272v60.451z"
      ></path>
      <path
        fill="#F66B02"
        d="M68.215 79H29.776c-.98 0-1.776.895-1.776 2s.795 2 1.776 2h38.457c.981-.006 1.772-.906 1.767-2.01-.005-1.105-.804-1.996-1.785-1.99zM38.758 71c-.97 0-1.758.895-1.758 2s.787 2 1.758 2h20.484c.971 0 1.758-.895 1.758-2s-.787-2-1.758-2H38.758z"
      ></path>
    </svg>
  )
}

export { Bell }
