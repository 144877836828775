import React, {FC} from 'react'
import {
  Stack,
  Center,
  Box,
  Switcher,
  Frame,
  Text,
  PrimaryButton,
} from '../common'
import {Tabs} from '../components/Tabs'
import {PointsGrid} from '../components/PointsGrid'
import {PointsList} from '../components/PointsList'
import {Layout} from '../components/Layout'
import {ProductFeatures} from '../components/ProductFeatures'
import {ZHelmet} from '../components/ZHelmet'
import {Link} from 'gatsby'
import {ProductPageType} from '../data/productsData'

export default function BusinessLoans(props) {
  // const product = PRODUCTS[0]
  const product = props.pageContext.product as ProductPageType

  return (
    <Layout>
      <ZHelmet
        title={product.meta.title || product.name + '| Zavron'}
        // title={product.meta.title}
        description={product.meta.description}
        keywords={product.meta.keywords}
      />
      <Stack space="var(--s5)">
        <Landing product={product} />
        <ProductFeatures features={product.features} />
        <Box>
          <Center gutters="var(--s2)" maxWidth="var(--max-width)">
            <Tabs
              tabs={[
                // {
                //   title: "Features & Benefits",
                //   key: "features",
                //   element: <PointsGrid data={FEATURES} />,
                // },
                {
                  title: 'Eligibility Criteria',
                  key: 'eligibility',
                  element: <Eligibility product={product} />,
                },
                {
                  title: `Apply For ${product.name}`,
                  key: 'apply',
                  element: <ApplyProcedure product={product} />,
                },
              ]}
            />
          </Center>
        </Box>
      </Stack>
    </Layout>
  )
}

type SectionProps = {
  product: ProductPageType
}

const Landing: FC<SectionProps> = props => {
  const {product} = props

  const contentParts = product.landing.content.split('||')
  const contentPartUrl =
    contentParts.length > 1 ? contentParts[1].split('|') : null

  return (
    <Box>
      <Center gutters="var(--s2)" maxWidth="var(--max-width)">
        <Switcher threshold="var(--min-width)" space="var(--s2)" max={2}>
          <Box>
            <Frame numerator={29} denominator={20}>
              <img src={product.landing.graphics_image} alt={product.name} />
            </Frame>
          </Box>
          <Stack style={{flexGrow: 2}} space="var(--s2)">
            <Stack space="var(--s-4)" style={{paddingTop: 'var(--s2)'}}>
              <Text as="h1" variant="h2">
                {product.name}
              </Text>
              <Text
                variant="h4"
                style={{
                  fontFamily: 'var(--body-font-family)',
                  whiteSpace: 'pre-line',
                }}
                color="light">
                {contentParts[0]}{' '}
                {!!contentPartUrl && (
                  <>
                    <Link to={contentPartUrl[1].trim()}>
                      <b>{contentPartUrl[0]}</b>
                    </Link>{' '}
                    {contentParts[2]}
                  </>
                )}
              </Text>
            </Stack>
          </Stack>
        </Switcher>
      </Center>
    </Box>
  )
}

const Eligibility: FC<SectionProps> = props => {
  const {product} = props

  const eligibility = product.eligibility

  return (
    <Box style={{paddingTop: 'var(--s3)'}}>
      <Stack space="var(--s4)">
        <Stack space="var(--s2)">
          <Text
            variant="h4"
            color="light"
            style={{fontFamily: 'var(--body-font-family)'}}>
            {
              //TODO:? maybe we can set dangerouslyInnerHtml = .join("<br/>");
              eligibility.content.map((text, index) => {
                return (
                  <>
                    {text} {index < eligibility.content.length ? <br /> : ''}
                  </>
                )
              })
            }
          </Text>
          {eligibility.type === 'list' ? (
            <PointsList data={eligibility.data} />
          ) : (
            <PointsGrid data={eligibility.data} />
          )}
        </Stack>
        <Switcher threshold="var(--min-width)" space="var(--s2)" max={2}>
          <Stack space="var(--s0)">
            <Text
              variant="h4"
              style={{
                fontFamily: 'var(--body-font-family)',
                color: 'var(--secondary-color)',
              }}>
              How To Calculate EMI On {product.name}?
            </Text>
            <Text
              variant="h4"
              color="light"
              style={{fontFamily: 'var(--body-font-family)'}}>
              Calculating EMI on {product.name} is easy. Use an EMI calculator
              and enter your loan amount, interest rate and tenure. Your EMI
              will be displayed instantly.
            </Text>
          </Stack>
          <Box>{''}</Box>
        </Switcher>
      </Stack>
    </Box>
  )
}

const ApplyProcedure: FC<SectionProps> = props => {
  const {product} = props

  const applyProcedure = product.apply_procedure

  return (
    <Box style={{paddingTop: 'var(--s3)'}}>
      <Stack space="var(--s1)">
        <Switcher threshold="var(--min-width)" space="var(--s2)" max={2}>
          <Text
            variant="h4"
            color="light"
            style={{fontFamily: 'var(--body-font-family)'}}>
            {
              //TODO:? maybe we can set dangerouslyInnerHtml = .join("<br/>");
              applyProcedure.content.map((text, index) => {
                return (
                  <>
                    {text} {index < applyProcedure.content.length ? <br /> : ''}
                  </>
                )
              })
            }

            {/* We are at your fingertips with ZCash, our mobile
            app, or you can apply on our website and get loans quickly. */}
          </Text>
          <Box>{''}</Box>
        </Switcher>
        <PointsGrid data={product.apply_procedure.data} />
        <Stack align="center">
          <PrimaryButton style={{width: 350}}>
            <Link to={`/apply`}>Apply Loan</Link>
          </PrimaryButton>
        </Stack>
      </Stack>
    </Box>
  )
}
