import React from "react"

function NextIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <g clip-path="url(#clip0)">
        <path
          fill="#F66B02"
          fillRule="evenodd"
          d="M16 0a16 16 0 110 32 16 16 0 010-32zm-6 15a1 1 0 000 2h9.586l-4.294 4.292a1.001 1.001 0 001.416 1.416l6-6a1 1 0 000-1.416l-6-6a1.001 1.001 0 00-1.416 1.416L19.586 15H10z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path
            fill="#fff"
            d="M0 0H32V32H0z"
            transform="rotate(-90 16 16)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export { NextIcon }
